import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Button from '../components/Button';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';
import FormInputField from '../components/FormInputField/FormInputField';
import { navigate } from 'gatsby';
import * as styles from '../components/Contact/Contact.module.css';
import { getCart, saveCart } from '../helpers/cartUtils';

const CheckoutFormPage = () => {
  const [state, handleSubmit] = useForm("xqkrabep");
  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
  });

  useEffect(() => {
    if (state.succeeded) {
      saveCart([]); // Reset the cart after successful submission
      navigate('/orderConfirm');
    }
  }, [state.succeeded]);

  const handleChange = (id, value) => {
    setFormFields(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleCheckoutSubmit = (event) => {
    event.preventDefault();
    const cart = getCart();
    const formattedCartItems = cart.map(item =>
      `ID: ${item.id}\n` +
      `Name: ${item.name}\n` +
      `Price: ${item.price}\n` +
      `Quantity: ${item.quantity}\n\n`
    ).join('');

    const message = `Requested Items:\n\n${formattedCartItems}`;

    handleSubmit({
      ...formFields,
      message: message,
    });
  };

  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>Checkout</h1>
          <p>Please fill out this form so we can process your order.</p>
          <div className={styles.contactContainer}>
            <form onSubmit={handleCheckoutSubmit} className={styles.contactForm}>
              <FormInputField
                id="name"
                value={formFields.name}
                handleChange={(id, e) => handleChange(id, e)}
                type="text"
                labelName="Full Name"
                required
              />
              <FormInputField
                id="email"
                value={formFields.email}
                handleChange={(id, e) => handleChange(id, e)}
                type="email"
                labelName="Email"
                required
              />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
              <Button
                className={styles.customButton}
                level="primary"
                type="submit"
                disabled={state.submitting}
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default CheckoutFormPage;
